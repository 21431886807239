<template>
    <div class="google-map new-updated-design" style="height: 100vh;" ref="childref">
        <vue-snotify></vue-snotify>
        <div id="menu" class="menu-items">
            <div class="menu-item">
                <input id="satellite-v9" name="flexRadioDefault" type="radio" v-model="default_map"
                   @click="saveMapStyle('satellite-v9')" value="mapbox://styles/mapbox/satellite-v9"
                   :checked="default_map === 'mapbox://styles/mapbox/satellite-v9' && !is_heatMap">
                <label class="map-label" for="satellite-v9">{{ $t('satellite') }}</label>
            </div>
            <div class="menu-item">
                <input id="light-v10" name="flexRadioDefault" class="ml-2" type="radio" v-model="default_map"
                   @click="saveMapStyle('light-v10')" value="mapbox://styles/mapbox/light-v10"
                   :checked="default_map === 'mapbox://styles/mapbox/light-v10'">
                <label class="map-label" for="light-v10">{{ $t('light') }}</label>
            </div>
            <div class="menu-item">
                <input id="dark-v10" name="flexRadioDefault" type="radio" class="ml-2" v-model="default_map"
                   @click="saveMapStyle('dark-v10')" value="mapbox://styles/mapbox/dark-v10"
                   :checked="default_map === 'mapbox://styles/mapbox/dark-v10'">
                <label class="map-label" for="dark-v10">{{ $t('dark') }}</label>
            </div>
            <div class="menu-item">   
                <input id="outdoors-v11" name="flexRadioDefault" type="radio" class="ml-2 m-input" v-model="default_map"
                   @click="saveMapStyle('outdoors-v11')" value="mapbox://styles/mapbox/outdoors-v11"
                   :checked="default_map === 'mapbox://styles/mapbox/outdoors-v11'">
                <label class="map-label" for="outdoors-v11">{{ $t('outdoors') }}</label>
            </div>
            <div class="menu-item">
                <input id="heat-map" name="flexRadioDefault" type="radio" class="ml-2"
                   @click="saveMapStylee('satellite-v9')" value="mapbox://styles/mapbox/satellite-v9"
                   :checked="is_heatMap">
            <label class="map-label" for="heat-map">{{ $t('heat-map') }}</label>
            </div>
            <b-button v-if="show_category_count" class="variant-orange ml-2" size="sm" @click="openSiderBar">{{$t('total-category-counts')}}</b-button>
            <b-button v-if="mapLoaded && show_filter" class="ml-2 variant-orange" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>
            <div class="lang-e4">
                <LanguageSwitcher class="spotter-lang-select" user="spotter"/>
            </div>
            <!-- <b-button v-if="mapLoaded" class="variant-orange ml-2" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>    -->
        </div>

        <div>
        <TotalCategoryCount ref="sidebar" :countableQuestions="countableQuestions" :totalSpots="totalSpots" :client_url="client_url" user_type="spotter"/>
        </div>
        <div class="map-container" id="mapContainer">
            <div style="display: none;" id="coordinates">
            </div>
        </div>  
        <div class="mobile-filter-group">
            <b-button class="variant-orange" size="sm" @click="openSiderBar">{{$t('total-category-counts')}}</b-button>
            <b-button v-if="mapLoaded" class="ml-2 variant-orange f-btn" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>
        </div>
     <!--   <button v-if="mapLoaded" class="filter-group" variant="info" size="sm" @click="openFilters">{{ $t('filters-btn') }}</button>   -->
        <!--        <button type="button" @click="openFullScreenMap" class="btn btn-success">Open Map</button>-->
        <b-modal id="modal-addSpot" no-close-on-backdrop :title="$t('add-spot')" size="md" :hide-footer="true" modal-class="custom-md-modal">
            <div class="forms-step-section">
                <div class="forms-step-section">
                    <form @submit.stop.prevent="registerSpot">
                        <div class="vue-step-wizard">
                            <div class="step-header">
                                <div class="step-progress">
                                    <div class="bar progressbar" :style="{width: width}"></div>
                                </div>
                                <ul class="step-pills">
                                    <li class="step-item active"><a href="javascript:void(0)" @click="nextSteps(1)"
                                                                    class="step-link"><span
                                            class="tabStatus">1 </span><span
                                            class="tabLabel">{{ $t('step-one') }}</span></a></li>
                                    <li class="step-item" :class="{active: active2}" @click="nextSteps(2)"><a
                                            href="javascript:void(0)" class="step-link"><span
                                            class="tabStatus">2 </span><span
                                            class="tabLabel">{{ $t('step-two') }}</span></a></li>
                                    <li class="step-item" :class="{active: active3}" @click="nextSteps(3)"><a
                                            href="javascript:void(0)" class="step-link"><span
                                            class="tabStatus">3 </span><span
                                            class="tabLabel">{{ $t('step-three') }}</span></a></li>
                                </ul>
                            </div>
                            <div v-if="current_step === 1">
                                <div class="step-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('category*')" label-for="Category">
                                                <b-form-select :options="categories" value-field="id" text-field="name"
                                                               @change="dataCollectionQuestions($event)"
                                                               v-model="$v.spotForm.category_id.$model"
                                                               :state="$v.spotForm.category_id.$dirty ? !$v.spotForm.category_id.$error : null"
                                                               aria-describedby="input-2-live-feedback">
                                                               <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.category_id.required"
                                                                         id="input-2-live-feedback">
                                                    {{ $t("field-required.") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('spot-type*')" label-for="spot_type_id">
                                                <b-form-select v-model="$v.spotForm.spot_type_id.$model"
                                                               :state="$v.spotForm.spot_type_id.$dirty ? !$v.spotForm.spot_type_id.$error : null"
                                                               :options="spot_types" value-field="id" text-field="name">
                                                               <b-form-select-option v-if="spot_types.length === 0" :disabled = true>{{ $t('no-spot-type-found') }}</b-form-select-option>
                                                            </b-form-select>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.spot_type_id.required"
                                                                         id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('location*')" label-for="location_id">
                                                <b-form-select v-model="$v.spotForm.location_id.$model"
                                                                @change="fixLocationsDropdown($event)"
                                                               :state="$v.spotForm.location_id.$dirty ? !$v.spotForm.location_id.$error : null"
                                                               :options="locations" value-field="id" text-field="name">
                                                               <b-form-select-option v-if="locations.length === 0" :disabled = true>{{ $t('no-location-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.location_id.required"
                                                                         id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('fix-location')" label-for="fix_location_id">
                                                <b-form-select @change="getFixLocation($event)"  v-model="spotForm.fix_location_id" aria-describedby="input-1-live-feedback" :options="fixLocations"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="fixLocations.length === 0" :disabled = true>{{ $t('no-fix-location-found') }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-form-group id="detail" label="" label-for="detail">
                                        <b-form-textarea id="mobile_phone" :placeholder="$t('detail')"
                                                         v-model="spotForm.detail"></b-form-textarea>
                                    </b-form-group>

                                    <b-form-group id="spot_date" :label="$t('spot-date')" label-for="spot_date">
                                        <b-form-input id="spot_date" type="datetime-local"
                                                      v-model="spotForm.spot_date"></b-form-input>
                                    </b-form-group>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group id="latitude" :label="$t('latitude-example')"
                                                          label-for="latitude">
                                                <b-form-input type="number" id="latitude" :placeholder="$t('latitude')"
                                                              v-model="$v.spotForm.latitude.$model"
                                                              :state="$v.spotForm.latitude.$dirty ? !$v.spotForm.latitude.$error : null"
                                                              @change="changePinLat($event)"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.latitude.required"
                                                                         id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.latitude.between"
                                                                         id="input-2-live-feedback">
                                                    {{ $t('Value must be between -90 and 90') }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group id="longitude" :label="$t('longitude-example')"
                                                          label-for="longitude">
                                                <b-form-input type="number" id="longitude" :placeholder="$t('longitude')"
                                                              v-model="$v.spotForm.longitude.$model"
                                                              :state="$v.spotForm.longitude.$dirty ? !$v.spotForm.longitude.$error : null"
                                                              @change="changePinLong($event)"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.longitude.required"
                                                                         id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.spotForm.longitude.between"
                                                                         id="input-2-live-feedback">
                                                    {{ $t('Value must be between -180 and 180') }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="step-footer">
                                    <div role="group" class="btn-group">
                                        <button disabled="disabled" type="button"
                                                class="step-button step-button-previous">{{ $t('previous-btn') }}
                                        </button>
                                        <button type="button" class="step-button step-button-next"
                                                @click="nextSteps(2)">{{ $t('next-btn') }}
                                        </button>
                                        <!---->
                                    </div>
                                </div>
                            </div>
                            <div v-if="current_step === 2">
                                <div class="step-body">
                                    <div class="col-md-12" v-for="category in spotForm.questions" :key="category.id">
                                        <h5>{{category.category.name}}</h5>
                                        <div v-for="question in category.questions" :key="question.id">
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    {{question.question}}
                                                </div>
                                                <div class="col-md-6" v-if="question.responses">
                                                    <!--                                                    <b-form-select v-model="question.answer" :options="question.responses" class="custom-select "    :class = "(question.answer === '' && question.required === 1 && isSubmittedStep2 === true)?'is-invalid':''"></b-form-select>-->
                                                    <b-form-select v-model="question.answer"
                                                                   :options="question.responses" class="custom-select "
                                                                   :class="{ 'is-invalid' : question.answer === '' && question.required === 1 && isSubmittedStep2 === true, 'is-valid':question.answer !== '' && question.required === 1 && isSubmittedStep2 === true }"></b-form-select>
                                                    <div v-if="question.answer === '' && question.required === 1 && isSubmittedStep2"
                                                         class="custom-invalid-feedback" id="input-2-live-feedback">
                                                        {{ $t("field-required") }}
                                                    </div>
                                                </div>
                                                <div class="col-md-6" v-else>
                                                    <b-form-input type="text" :placeholder="$t('Please write your answer')"
                                                                  v-model="question.answer"></b-form-input>
                                                    <div v-if="question.answer === '' && question.required === 1 && isSubmittedStep2"
                                                         class="custom-invalid-feedback" id="input-2-live-feedback">
                                                        {{ $t("field-required") }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="question.logic === 1 && question.answer === 'Yes' && question.subQuestions">
                                                <div class="row mt-2" v-for="sub_question in question.subQuestions"
                                                     :key="sub_question.id">
                                                    <div class="col-md-6">{{sub_question.question}}</div>
                                                    <div class="col-md-6" v-if="sub_question.answer_type == 'D'">
                                                        <b-form-select v-model="sub_question.answer"
                                                                       :options="sub_question.responses"
                                                                       class="custom-select"></b-form-select>
                                                    </div>
                                                    <div class="col-md-6" v-if="sub_question.answer_type == 'I'">
                                                        <b-form-input type="text" id="sub_question_response"
                                                                      placeholder=""
                                                                      v-model="sub_question.answer"></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="step-footer">
                                    <div role="group" class="btn-group">
                                        <button @click="nextSteps(1)" type="button"
                                                class="step-button step-button-previous">{{ $t('previous-btn') }}
                                        </button>
                                        <button type="button" class="step-button step-button-next"
                                                @click="nextSteps(3)">{{ $t('next-btn') }}
                                        </button>
                                        <!---->
                                    </div>
                                </div>
                            </div>
                            <div v-if="current_step === 3">
                                <div class="step-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <!-- <div class="upload-img">
                                                    <img :src="url" alt="" class="mb-1">
                                                </div> -->
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType0">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(0)"></i></a>
                                                </div>
                                                <img :src="spotForm.url" alt="" class="mb-1" v-if="imageType0 === 'image'">
                                                <audio controls :src="spotForm.url"  v-if="imageType0 === 'audio'"></audio>
                                            </div>
                                            <b-form-group label="" label-for="input8">
                                                <!-- <b-form-file ref="fileInput" class="mt-2"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        id="input8"
                                                        :class="{'is-invalid': this.imageError}"
                                                        placeholder="Choose a file..."
                                                        @change="onFileSelected">
                                                    </b-form-file> -->
                                                <b-form-file ref="imageInput" class="mt-2"
                                                             accept="image/png, image/jpeg, image/bmp" id="input8"
                                                             :class="{'is-invalid': this.imageError}"
                                                             :placeholder="$t('upload-image')"
                                                             @change="onFileSelected($event, 'image')"></b-form-file>
                                                <b-form-file ref="audioInput" class="mt-2" accept=".mp3,audio/*"
                                                             id="inputaudio8" :class="{'is-invalid': this.imageError}"
                                                             :placeholder="$t('upload-audio')"
                                                             @change="onFileSelected($event, 'audio')"></b-form-file>

                                                <div v-if="imageError" class="custom-invalid-feedback"
                                                     id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </div>
                                                <!-- <div v-if="imageSizeError" class="custom-invalid-feedback"
                                                     id="input-9-live-feedback">
                                                    {{  $t('image-size-must-be-less-than-5mb') }}
                                                </div> -->
                                                <!-- <div v-if="audioSizeError" class="custom-invalid-feedback"
                                                     id="input-9-live-feedback">
                                                    {{ $t('audio-size-must-be-less-than-100mb') }}
                                                </div> -->
                                                <div v-if="imageSizeError" class="custom-invalid-feedback"
                                                     id="input-9-live-feedback">
                                                     {{ $t('image-size-must-be-less-than-3mb') }}.
                                                </div>
                                                <div v-if="audioSizeError && this.version_id === 1" class="custom-invalid-feedback"
                                                 id="input-9-live-feedback">
                                                 {{ $t('audio-size-must-be-less-than-3mb')}}
                                                </div>
                                                <div v-if="this.version_id !== 1 && audioSizeError" class="custom-invalid-feedback"
                                                 id="input-9-live-feedback">
                                                {{ $t('audio-size-must-be-less-than-10mb') }}
                                                </div>
                                            </b-form-group>

                                        </div>
                                        <div class="col-md-4">
                                            <!-- <div class="upload-img">
                                                    <img :src="spotForm.url1" alt="" class="mb-1">
                                                </div> -->
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType1">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(1)"></i></a>
                                                </div>
                                                <img :src="spotForm.url1" alt="" class="mb-1" v-if="imageType1 === 'image'">
                                                <audio controls :src="spotForm.url1" v-if="imageType1 === 'audio'"></audio>
                                            </div>
                                            <!-- <b-form-group label="" label-for="input8">
                                                <b-form-file ref="fileInput" class="mt-2"
                                                    accept="image/png, image/jpeg, image/bmp"
                                                    id="input8"
                                                    placeholder="Choose a file..."
                                                    @change="onFileSelected1"></b-form-file>
                                                </b-form-group> -->
                                            <b-form-group label label-for="input8">
                                                <b-form-file ref="image1Input" class="mt-2"
                                                             accept="image/png, image/jpeg, image/bmp" id="input"
                                                             :placeholder="$t('upload-image')"
                                                             @change="onFileSelected1($event, 'image')"></b-form-file>
                                                <b-form-file ref="audio1Input" class="mt-2" accept=".mp3,audio/*"
                                                             id="inputaudio9" :class="{'is-invalid': this.imageError}"
                                                             :placeholder="$t('upload-audio')"
                                                             @change="onFileSelected1($event, 'audio')"></b-form-file>
                                            </b-form-group>
                                            <!-- <div v-if="imageSizeError1" class="custom-invalid-feedback"
                                                 id="input-10-live-feedback">
                                                {{  $t('image-size-must-be-less-than-5mb') }}
                                            </div> -->
                                            <!-- <div v-if="audioSizeError1" class="custom-invalid-feedback"
                                                 id="input-10-live-feedback">
                                                 {{ $t('audio-size-must-be-less-than-100mb') }}
                                            </div> -->
                                            <div v-if="imageSizeError1" class="custom-invalid-feedback"
                                                 id="input-10-live-feedback">
                                                 {{ $t('image-size-must-be-less-than-3mb') }}.
                                            </div>
                                            <div v-if="audioSizeError1 && this.version_id === 1" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                            <div v-if="this.version_id !== 1 && audioSizeError1" class="custom-invalid-feedback"
                                                id="input-9-live-feedback">
                                                {{ $t('audio-size-must-be-less-than-10mb') }}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <!-- <div class="upload-img">
                                                    <img :src="spotForm.url2" alt="" class="mb-1">
                                                </div> -->
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType2">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(2)"></i></a>
                                                </div>
                                                <img :src="spotForm.url2" alt="" class="mb-1" v-if="imageType2 === 'image'">
                                                <audio controls :src="spotForm.url2" v-if="imageType2 === 'audio'"></audio>
                                            </div>
                                            <!-- <b-form-group label="" label-for="input8">
                                                    <b-form-file ref="fileInput" class="mt-2"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        id="input8"
                                                        placeholder="Choose a file..."
                                                        @change="onFileSelected2"></b-form-file>
                                                </b-form-group> -->
                                            <b-form-group label label-for="input10">
                                                <b-form-file ref="image2Input" class="mt-2"
                                                             accept="image/png, image/jpeg, image/bmp" id="input10"
                                                             :placeholder="$t('upload-image')"
                                                             @change="onFileSelected2($event, 'image')"></b-form-file>
                                                <b-form-file ref="audio2Input" class="mt-2" accept=".mp3,audio/*"
                                                             id="inputaudio10" :class="{'is-invalid': this.imageError}"
                                                             :placeholder="$t('upload-audio')"
                                                             @change="onFileSelected2($event, 'audio')"></b-form-file>
                                            </b-form-group>
                                            <!-- <div v-if="imageSizeError2" class="custom-invalid-feedback"
                                                 id="input-11-live-feedback">
                                                {{  $t('image-size-must-be-less-than-5mb') }}
                                            </div> -->
                                            <!-- <div v-if="audioSizeError2" class="custom-invalid-feedback"
                                                 id="input-11-live-feedback">
                                                 {{ $t('audio-size-must-be-less-than-100mb') }}
                                            </div> -->
                                            <div v-if="imageSizeError2" class="custom-invalid-feedback"
                                                 id="input-11-live-feedback">
                                                 {{ $t('image-size-must-be-less-than-3mb') }}.
                                            </div>
                                            <div v-if="audioSizeError2 && this.version_id === 1" class="custom-invalid-feedback" id="input-11-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                            <div v-if="this.version_id !== 1 && audioSizeError2" class="custom-invalid-feedback"
                                                id="input-9-live-feedback">
                                                {{ $t('audio-size-must-be-less-than-10mb') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="step-footer">
                                    <div role="group" class="btn-group">
                                        <button @click="nextSteps(2)" type="button"
                                                class="step-button step-button-previous">{{ $t('previous-btn') }}
                                        </button>
                                        <button @click="nextSteps('submit')" type="button"
                                                class="step-button step-button-submit" :disabled="isDisabled || isLoading2">
                                                <template v-if="isLoading2">
                                                    <i class="fas fa-spinner fa-spin"></i>{{ $t('submitting') }}...
                                                </template>
                                                <template v-else>
                                                    <span>{{ $t('submit-btn') }}</span>
                                                </template>
                                        </button>
                                        <!---->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-messaging" :title="$t('spotter-chat')" size="xl" :hide-footer="true">
            <Chat :userEmailProp="spotterEmail" :spotterAuth="spotterAuth" spotterChat="yes"
                  :spotterClientId="spotterClientId"></Chat>
        </b-modal>

        <b-modal id="modal-clusters" :title="$t('cluster-spots')" scrollable size="md" :hide-footer="true">
            <ClusterSpotsModal :spotsData="spotsData" @openParentSpot="openSpotterSpotDetail"></ClusterSpotsModal>
        </b-modal>

        <FiltersModal @applyFilter="applyFilter" @resetMap="getClientSpots" ref="filterComponent" :client_url="client_url" user_type="spotter"></FiltersModal>
        <SpotDetailsModal ref="spotDetails"></SpotDetailsModal>
    </div>
</template>

<script>
    import Vue from "vue";
    import mapboxgl from "mapbox-gl";
    import API from '@/citizen_api';
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {
        mapState
    } from "vuex";
    import moment from 'moment'
    import {
        helpers,
        required,
        minLength,
        email,
        between,
        sameAs,
        requiredIf
    } from "vuelidate/lib/validators";
    import {
        validationMixin
    } from "vuelidate";
    import {
        eventBus
    } from "../main";
    import Chat from '../client/chat.vue'
    import ClusterSpotsModal from '../client/ClusterSpotsModal.vue'
    import SpotDetailsModal from './SpotDetailsModal.vue';
    // import {FormWizard, TabContent, ValidationHelper} from 'vue-step-wizard'
    import "vue-step-wizard/dist/vue-step-wizard.css";
    import Compressor from 'compressorjs';
    import {
        FormWizard,
        TabContent,
        ValidationHelper
    } from 'vue-step-wizard'
    import LanguageSwitcher from '@/components/lang/LanguageSwitcher.vue';
    import FiltersModal from './FiltersModal.vue';
    import i18n from '@/i18n';
    import TotalCategoryCount from '../client/TotalCategoryCounts';

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    // const MAX_SIZE = 104857600;
    const MAX_SIZE = 10485760;
    const MAX_SIZE_CS_VERSION = 3145728;
    export default {
        ...mapState(["click"]),
        mixins: [validationMixin],
        name: 'BasicStepperForm',
        components: {
            Chat,
            ClusterSpotsModal,
            LanguageSwitcher,
            FiltersModal,
            TotalCategoryCount,
            SpotDetailsModal
        },
        data() {
            return {
                version_id: null,
                routeTracks: [],
                show_filter:  null,
                show_menu: null,
                show_category_count: null,
                showWeather: false,
                accessToken: 'pk.eyJ1Ijoid2F0Y2hzcG90dGVyIiwiYSI6ImNsMmY0OTgxdzA3MW0zYm1jMmY5MGY5OG4ifQ.vEle7r52YhgPJ8D-MVlZ2A',
                latitude: '',
                longitude: '',
                map: [],
                showBackButton: false,
                imageSizeError: false,
                audioSizeError: false,
                imageSizeError1: false,
                audioSizeError1: false,
                imageSizeError2: false,
                audioSizeError2: false,
                isLoading2:false,
                mapFeatures: [],
                ready: false,
                data2: [],
                isSubmittedStep2: false,
                marker: null,
                appImage: null,
                isDisabled: false,
                imageError: false,
                requirePhoto: 1,
                current_step: 1,
                active2: false,
                width: '33.3333%',
                active3: false,
                adminMapStyle: '',
                default_map: '',
                is_heatMap: false,
                // heatMAp: '1',
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    category_id: '',
                    location_id: '',
                    fix_location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: window.location.href.split('#')[0],
                    image: [],
                    url: null,
                    image1: [],
                    url1: null,
                    image2: [],
                    url2: null,
                    media_types: []
                },
                questionArray: [],
                spot_types: [],
                locations: [],
                fixLocations: [],
                categories: [],
                default_latitude: '',
                default_longitude: '',
                default_map_zoom: 14,
                spotDetail: [],
                spotDetailImages: [],
                spotterEmail: '',
                spotterAuth: '',
                spotterClientId: '',
                client_url: window.location.href.split('#')[0],
                imageType0: '',
                imageType1: '',
                imageType2: '',
                spotsData: [],
                mapLoaded: false,
                totalSpots: 0,
                markerColor: '',
            };
        },
        validations: {
            spotForm: {
                spot_type_id: {
                    required
                },
                category_id: {
                    required
                },
                location_id: {
                    required
                },
                latitude: {
                    required: function () {
                        return this.spotForm.requireLatLong === 1 ? !!this.spotForm.latitude : true;
                    },
                    between(value) {
                        return between(-90, 90)(value)
                    }
                },
                longitude: {
                    required: function () {
                        return this.spotForm.requireLatLong === 1 ? !!this.spotForm.longitude : true;
                    },
                    between(value) {
                        return between(-180, 180)(value)
                    }
                },
                // questions: {
                //     $each: {
                //         answer: {
                //             required
                //         }
                //     }
                // }
            },
        },
        computed: {},
        beforeMount() {
            this.getAppSetting();
            this.getClientSpots();
        },
        mounted() {
            // document.getElementById('view-full').addEventListener('click', console.log('ok'))
            var date_time = moment().format('YYYY-MM-DDTHH:mm');
            this.$store.commit('decrement');
            this.spotForm.spot_date = date_time;
            this.$store.watch(
                (state) => {
                    return this.$store.state.click // could also put a Getter here
                },
                (newValue, oldValue) => {
                    //something changed do something
                    if (newValue === 1) {
                        this.active2 = false;
                        this.isSubmittedStep2 = false;
                        this.width = '33.3333%';
                        this.active3 = false;
                        this.current_step = 1;
                        this.openAddSpot();
                    }
                },
                //Optional Deep if you need it
                {
                    deep: true
                }
            )
        },
        created() {
            this.$EventBus.$on("reinitializeMap", (data) => {
                this.map.remove();
                this.getClientSpots();
            });
            this.$EventBus.$on("loggedIn", (data) => {
                this.getUserSettings();
            });

            this.$EventBus.$on("spotPoint", (data) => {
                this.data2.features.push(data);
                this.map.getSource("earthquakes").setData(this.data2);
            });
            this.$EventBus.$on("showMessaging", (data) => {
                this.$root.$emit("bv::show::modal", "modal-messaging");
            });
            this.$EventBus.$on("showClusterSpots", (data) => {
                this.$root.$emit("bv::show::modal", "modal-clusters");
            });
            this.$EventBus.$on("showClusterSpots", (data) => {
                this.$root.$emit("bv::show::modal", "modal-clusters");
            });
        },
        methods: {
            removeImage(index) {
                if (index === 0) {
                    this.spotForm.url = '';
                    this.spotForm.image = '';
                    this.imageType0 = '';
                    this.imageError = true;
                    this.spotForm.media_types[0] = '';
                    this.$refs.imageInput.reset();
                     this.$refs.audioInput.reset();
                } else if (index === 1) {
                    this.spotForm.url1 = '';
                    this.imageType1 = '';
                    this.spotForm.image1 = '';
                    this.spotForm.media_types[1] = '';
                    this.$refs.image1Input.reset();
                    this.$refs.audio1Input.reset();
                } else if (index === 2) {
                    this.spotForm.url2 = '';
                     this.imageType2 = '';
                    this.spotForm.image2 = '';
                    this.spotForm.media_types[2] = '';
                    this.$refs.image2Input.reset();
                    this.$refs.audio2Input.reset();
                }
            },
            getFixLocation(e) {
                const data = {
                    id: e,
                };
                if(e === 0) {
                    console.log('this.marker._lngLat :', this.marker._lngLat);
                    this.spotForm.longitude = this.marker._lngLat.lng;
                    this.spotForm.latitude = this.marker._lngLat.lat;
                    // this.ref.getLatLong(lngLat);
                } else {
                    API.getFixLocation(
                        data,
                        data => {
                            this.spotForm.longitude = data.longitude;
                            this.spotForm.latitude = data.latitude;
                        },
                        err => {}
                    );
                }
            },
            addRouteTracksToMap() {
                if (this.routeTracks.length > 0) {
                    const coordinates = this.routeTracks.map(track => [track.longitude, track.latitude]);

                    // Add a new source for the route
                    this.map.addSource('route', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [{
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates
                                },
                                properties: {}
                            }]
                        }
                    });

                    // Add the route layer
                    this.map.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        paint: {
                            'line-color': '#888',
                            'line-width': 4
                        }
                    });
                }
            },
            fixLocationsDropdown(e) {
                const data = {
                    location_id: e,
                    client_url: window.location.href.split('#')[0],
                };
                API.fixLocationsDropdown(
                    data,
                    data => {
                        if(data.status === 200) {
                            // if(data.data.length > 0) {
                                const first = {
                                    id: 0, 
                                    name: 'Current Location'
                                }
                                data.data.unshift(first);
                                this.fixLocations = data.data;
                            // }
                        }
                    },
                    err => {}
                )
            },
            checkImage(image) {
                let found = image.search('.mp3');
                if (found === -1) {
                    return true;
                } else {
                    return false;
                }
            },
            getClientAdminSpots() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientSpotss(
                    data,
                    data => {
                        const spot_dImage = data.default_image;
                        this.totalSpots = data.total_spots;

                        data.data.forEach(data => {
                            let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                            if (!exists) {
                                const d1 = {
                                    type: "Feature",
                                    properties: {
                                        id: data.id,
                                        title: (data.spot_type) ? data.spot_type.name : '',
                                        // spot_id: data.spot_type_id,
                                        spot_id: data.spot_id,
                                        description: (data.location) ? data.location.name : '',
                                        spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                                        spot_date: this.formatDate(data.spot_date),
                                        iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [data.longitude, data.latitude]
                                    }
                                }
                                this.mapFeatures.push(d1);
                            }
                        })

                        // if (navigator.geolocation) {
                        //     var self = this;
                        //     navigator.geolocation.getCurrentPosition(e => {
                        //         this.initMap([e.coords.longitude, e.coords.latitude])
                        //     }, function (error) {
                        //         if (error.code == error.PERMISSION_DENIED) {
                        //             // pop up dialog asking for location
                        //             self.initMap([self.default_longitude, self.default_latitude]);
                        //         }
                        //     })
                        // }
                        this.initHeatMap([this.default_longitude, this.default_latitude]);
                    },
                    err => {
                    }
                )
            },
            initHeatMap(lngLat) {
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: 'mapContainer',
                    style: (this.default_map) ? this.default_map : 'mapbox://styles/mapbox/light-v10',
                    center: lngLat,
                    zoom: this.default_map_zoom,
                    // projection: 'globe'
                });
                document.getElementById('light-v10').checked = false;
                this.map.on('style.load', () => {
                    this.map.setFog({});
                });

                this.map.on('load', () => {

                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }

                    // Add a geojson point source.
                    // Heatmap layers also work with a vector tile source.
                    this.map.addSource('earthquakes', {
                        'type': 'geojson',
                        'data': this.data2
                    });

                    const nav = new mapboxgl.NavigationControl();
                    this.map.addControl(nav, "top-right");

                    this.map.addLayer({
                            'id': 'earthquakes-heat',
                            'type': 'heatmap',
                            'source': 'earthquakes',
                            'maxzoom': 9,
                            'paint': {
                                // Increase the heatmap weight based on frequency and property magnitude
                                'heatmap-weight': [
                                    'interpolate', ['linear'],
                                    ['get', 'mag'],
                                    0,
                                    0,
                                    6,
                                    1
                                ],
                                // Increase the heatmap color weight weight by zoom level
                                // heatmap-intensity is a multiplier on top of heatmap-weight
                                'heatmap-intensity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    0,
                                    1,
                                    9,
                                    3
                                ],
                                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                // Begin color ramp at 0-stop with a 0-transparancy color
                                // to create a blur-like effect.
                                'heatmap-color': [
                                    'interpolate', ['linear'],
                                    ['heatmap-density'],
                                    0,
                                    'rgba(33,102,172,0)',
                                    0.2,
                                    'rgb(103,169,207)',
                                    0.4,
                                    'rgb(209,229,240)',
                                    0.6,
                                    'rgb(253,219,199)',
                                    0.8,
                                    'rgb(239,138,98)',
                                    1,
                                    'rgb(178,24,43)'
                                ],
                                // Adjust the heatmap radius by zoom level
                                'heatmap-radius': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    0,
                                    2,
                                    9,
                                    20
                                ],
                                // Transition from heatmap to circle layer by zoom level
                                'heatmap-opacity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7,
                                    1,
                                    9,
                                    0
                                ]
                            }
                        },
                        // 'waterway-label'
                    );

                    this.map.addLayer({
                            'id': 'earthquakes-point',
                            'type': 'circle',
                            'source': 'earthquakes',
                            'minzoom': 7,
                            'paint': {
                                // Size circle radius by earthquake magnitude and zoom level
                                'circle-radius': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7, ['interpolate', ['linear'],
                                        ['get', 'mag'], 1, 1, 6, 4
                                    ],
                                    16, ['interpolate', ['linear'],
                                        ['get', 'mag'], 1, 5, 6, 50
                                    ]
                                ],
                                // Color circle by earthquake magnitude
                                'circle-color': [
                                    'interpolate', ['linear'],
                                    ['get', 'mag'],
                                    1,
                                    'rgba(33,102,172,0)',
                                    2,
                                    'rgb(103,169,207)',
                                    3,
                                    'rgb(209,229,240)',
                                    4,
                                    'rgb(253,219,199)',
                                    5,
                                    'rgb(239,138,98)',
                                    6,
                                    'rgb(178,24,43)'
                                ],
                                'circle-stroke-color': 'white',
                                'circle-stroke-width': 1,
                                // Transition from heatmap to circle layer by zoom level
                                'circle-opacity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7,
                                    0,
                                    8,
                                    1
                                ]
                            }
                        },
                        // 'waterway-label'
                    );
                    // this.map.on('click', 'earthquakes-point', (e) => {
                    //     alert(e)
                    // })

                });

            },
            openClusterSpotDetail(aFeatures) {
                this.$EventBus.$emit('showClusterSpots');
            },
            initMap(lngLat) {
                this.mapLoaded = false;
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: "mapContainer",
                    style: (this.default_map) ? this.default_map : 'mapbox://styles/mapbox/light-v10',
                    center: lngLat,
                    zoom: this.default_map_zoom,
                    maxZoom: 14,
                    attributionControl: false
                });
                // more initialization
                this.spotForm.longitude = lngLat[0];
                this.longitude = lngLat[0];
                this.spotForm.latitude = lngLat[1];
                this.latitude = lngLat[1];
                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(lngLat).addTo(this.map);

                var coordinates = document.getElementById('coordinates');
                coordinates.style.display = 'block';
                coordinates.innerHTML = `Lat: ${parseFloat(this.latitude).toFixed(6)}, Lng: ${parseFloat(this.longitude).toFixed(6)}`; 
                var ref = this;
                this.marker.on('dragend', (e) => {
                    if(this.spotForm.fix_location_id === 0 || this.spotForm.fix_location_id === '') {
                        var lngLat = e.target.getLngLat();
                        // this.$refs.marker.mapObject.getLatLng();
                        ref.$EventBus.$emit('lngLatChange', lngLat);
                        ref.getLatLong(lngLat)
                    }
                    coordinates.innerHTML = `Lat: ${lngLat.lat.toFixed(6)}, Lng: ${lngLat.lng.toFixed(6)}`;  
                });

                var loadFeatures = this.mapFeatures;
                const geojson = {
                    'type': 'FeatureCollection',
                    'features': this.mapFeatures
                };

                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, "top-right");
                this.map.addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        trackUserLocation: true,
                        showUserHeading: true
                    })
                );

                this.map.on('load', () => {
                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }
                    this.map.addSource('earthquakes', {
                        type: 'geojson',
                        data: this.data2,
                        cluster: true,
                        clusterMaxZoom: 14,
                        clusterRadius: 50
                    });

                    this.map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': [
                                'step', ['get', 'point_count'],
                                '#FF7F50',
                                100,
                                '#003366',
                                750,
                                '#ff414e'
                            ],
                            'circle-radius': [
                                'step', ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });

                    this.map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12,
                            "text-allow-overlap" : true
                        },
                        paint: {
                            "text-color": "#ffffff"
                        }
                    });
                    // Fetch and add the route track data
                    const data = {
                        client_id: JSON.parse(localStorage.getItem('client_id')),
                    }
                    API.getTracksData(
                        data,
                        (data) => {
                            // Prepare the track data for the map
                            const trackCoordinates = data.map(track => [track.longitude, track.latitude]);

                            // Add a new source for the route tracks
                            this.map.addSource('routeTracks', {
                                type: 'geojson',
                                data: {
                                    type: 'FeatureCollection',
                                    features: [{
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: trackCoordinates
                                        },
                                        properties: {}
                                    }]
                                }
                            });

                            // Add a layer to display the route tracks with a curved, dotted style
                            this.map.addLayer({
                                id: 'route',
                                type: 'line',
                                source: 'routeTracks',
                                layout: {
                                    'line-cap': 'round',  // Rounded end for smoother curves
                                    'line-join': 'round'  // Rounded join for smoother corners
                                },
                                paint: {
                                    'line-color': '#888',  // Line color
                                    'line-width': 4,       // Line width
                                    'line-dasharray': [4, 4]  // Dash pattern: 4px dash, 4px space
                                }
                            });

                            // Add markers at each point along the route
                            trackCoordinates.forEach((coord, index) => {
                                const markerElement = document.createElement('div');
                                markerElement.style.backgroundColor = '#FF0000'; // Red color for the marker
                                markerElement.style.width = '20px';
                                markerElement.style.height = '20px';
                                markerElement.style.borderRadius = '50%'; // Make it circular
                                markerElement.style.border = '2px solid white'; // Add a white border for better visibility
                                
                                // Add marker to the map
                                new mapboxgl.Marker(markerElement)
                                    .setLngLat(coord)
                                    .setPopup(new mapboxgl.Popup({ offset: 25 }) // Add a popup
                                        .setHTML(`<p>Point ${index + 1}:<br>Lat: ${coord[1]}, Lng: ${coord[0]}</p>`)) // Popup content
                                    .addTo(this.map);
                            });
                        },
                        (error) => {
                            console.error('Error fetching route tracks:', error);
                        }
                    );
                    if (this.version_id === 2 || this.version_id === 3) {
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                                if (error) throw error;
                                // Add the image to the map style.
                                this.map.addImage('cat', image, {
                                    "sdf": "true"
                                });
                            });

                        let categoryColors = [];

                        this.mapFeatures.forEach((feature) => {
                            // Collect all category colors
                            categoryColors.push(feature.properties.markerColor || '');
                        });

                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            layout: {
                                "icon-image": [
                                    "match", ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin", ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale", ["BS"], "baskingshark", ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-size": 1,
                                "icon-padding": 2,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            },
                            paint: {
                                "icon-color": [
                                    "get",
                                    "icon-color"
                                ],
                                "icon-halo-blur": 0,
                                "icon-halo-color": [
                                    "get",
                                    "icon-halo-color"
                                ],
                                "icon-halo-width": 4
                            }
                        });

                        // Set the GeoJSON source data with individual colors for each feature
                        const featuresWithColors = this.mapFeatures.map((feature, index) => {
                            return {
                                ...feature,
                                properties: {
                                    ...feature.properties,
                                    'icon-color': categoryColors[index] || '',
                                    'icon-halo-color': categoryColors[index] || ''
                                }
                            };
                        });

                        this.map.getSource('earthquakes').setData({
                            type: 'FeatureCollection',
                            features: featuresWithColors
                        });
                    }
                    else
                    {
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                            if (error) throw error;
                            // Add the image to the map style.
                            this.map.addImage('cat', image);
                        });
                   
                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            // layout: {
                            //     'icon-image': 'cat', // reference the image
                            //     'icon-size': 0.25
                            // }
                            // paint: {
                            //     "text-color": "#d21010",
                            //     "icon-halo-color": "#d21010",
                            //     "icon-halo-width": 2
                            // },
                            layout: {
                                "icon-image": [
                                    "match", ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin", ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale", ["BS"], "baskingshark", ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-padding": 2,
                                // 'icon-size': 0.05,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            },
                        });
                    }

                    this.map.on('click', 'clusters', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        var clusterId = features[0].properties.cluster_id;
                        var zoom = this.map.getZoom();
                        console.log('zoom', zoom);
                        if (zoom < 14) {
                            this.map.getSource('earthquakes').getClusterExpansionZoom(
                                clusterId,
                                (err, zoom) => {
                                    if (err) return;

                                    this.map.easeTo({
                                        center: features[0].geometry.coordinates,
                                        zoom: zoom
                                    });
                                }
                            );
                        } else {
                            var point_count = e.features[0].properties.point_count;
                            var clusterSource = this.map.getSource("earthquakes");

                            clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                                this.spotsData = aFeatures;
                                this.spotsData.sort(function(a,b) {
                                    return new Date(b.properties.spot_date) - new Date(a.properties.spot_date);
                                });
                                this.openClusterSpotDetail(this.spotsData);
                            });
                        }
                    });
                    // if(this.version_id === 2 || this.version_id === 3){
                    //     this.mapFeatures.forEach((feature) => {
                    //         this.map.on('click', `unclustered-point-${feature.properties.id}`, (e) => {
                    //             const coordinates = e.features[0].geometry.coordinates.slice();
                    //             const spot_type = e.features[0].properties.title;
                    //             const id = e.features[0].properties.id;
                    //             const spot_id = e.features[0].properties.spot_id;
                    //             const iconUrl = e.features[0].properties.iconUrl;
                    //             const spotted_by = e.features[0].properties.spotted_username;
                    //             const spot_date = e.features[0].properties.spot_date;
                    //             const description = e.features[0].properties.description;
                    //             while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    //                 coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    //             }
                    //             // this.openSpotDetail(id, false)
                    //             this.$refs.spotDetails.openSpotterSpotDetail(id, false);
                    //         });
                    //         this.map.on('mouseenter', 'clusters', () => {
                    //             this.map.getCanvas().style.cursor = 'pointer';
                    //         });
                    //         this.map.on('mouseleave', 'clusters', () => {
                    //             this.map.getCanvas().style.cursor = '';
                    //         });
                    //     });
                    // }
                    // else{
                        this.map.on('click', 'unclustered-point', (e) => {
                            const coordinates = e.features[0].geometry.coordinates.slice();
                            const spot_type = e.features[0].properties.title;
                            const id = e.features[0].properties.id;
                            const spot_id = e.features[0].properties.spot_id;
                            const iconUrl = e.features[0].properties.iconUrl;
                            const spotted_by = e.features[0].properties.spotted_username;
                            const spot_date = e.features[0].properties.spot_date;
                            const description = e.features[0].properties.description;
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }
                            // this.openSpotDetail(id, false)
                            this.$refs.spotDetails.openSpotterSpotDetail(id, false);
                        });
                        this.map.on('mouseenter', 'clusters', () => {
                            this.map.getCanvas().style.cursor = 'pointer';
                        });
                        this.map.on('mouseleave', 'clusters', () => {
                            this.map.getCanvas().style.cursor = '';
                        });
                    // }
                });
                const logo = document.querySelector('.mapboxgl-ctrl-logo');
                if (logo) {
                    logo.remove();
                }
                this.mapLoaded = true;
            },
            callPopUp(e) {
            },
            openSpotterSpotDetail(id,event) {
                this.$refs.spotDetails.openSpotterSpotDetail(id,true)
            },
            getClientSpots() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                API.getClientSpots(
                    data,
                    data => {
                        const spot_dImage = data.default_image;
                        // this.mapFeatures = [];
                        this.totalSpots = data.total_spots;
                        data.data.forEach(data => {
                            let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                            if (!exists) {
                                const d1 = {
                                    type: "Feature",
                                    properties: {
                                        id: data.id,
                                        title: (data.spot_type) ? data.spot_type.name : '',
                                        // spot_id: data.spot_type_id,
                                        spot_id: data.spot_id,
                                        description: (data.location) ? data.location.name : '',
                                        spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                                        spotted_username: (data.spotter_user) ? data.spotter_user.user_name : '',
                                        spot_date: this.formatDate(data.spot_date),
                                        iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                        markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [data.longitude, data.latitude]
                                    }
                                }
                                this.mapFeatures.push(d1);
                            }
                        })
                        this.initMap([this.default_longitude, this.default_latitude]);
                    },
                    err => {
                    }
                )
            },
            // getClientSpots() {
            //     const data = {
            //         client_url: window.location.href.split('#')[0],
            //     }
            //     API.getClientSpots(
            //         data,
            //         data => {
            //             const spot_dImage = data.default_image;
            //             // this.mapFeatures = [];
            //             data.data.forEach(data => {
            //                 let exists = this.mapFeatures.find(p => p.properties.id == data.id)
            //                 if (!exists) {
            //                     const d1 = {
            //                         type: "Feature",
            //                         properties: {
            //                             id: data.id,
            //                             title: (data.spot_type) ? data.spot_type.name : '',
            //                             spot_id: data.spot_type_id,
            //                             description: (data.location) ? data.location.name : '',
            //                             spotted_by: (data.spotter_user) ? data.spotter_user.name : '',
            //                             spotted_username: data.spotter_user.user_name,
            //                             spot_date: this.formatDate(data.spot_date),
            //                             // iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
            //                         },
            //                         geometry: {
            //                             type: "Point",
            //                             coordinates: [data.longitude, data.latitude]
            //                         }
            //                     }
            //                     this.mapFeatures.push(d1);
            //                 }
            //             })
            //             this.initMap([this.default_longitude, this.default_latitude]);
            //         },
            //         err => {
            //         }
            //     )
            // },
            openAddSpot() {
                // Check if marker is defined and has _lngLat before accessing its properties
                if (this.marker && this.marker._lngLat) {
                    if (this.spotForm.fix_location_id === 0 || this.spotForm.fix_location_id === '') {
                        this.spotForm.longitude = this.marker._lngLat.lng;
                        this.spotForm.latitude = this.marker._lngLat.lat;
                    }
                } else {
                    // Handle the case where marker or _lngLat is not available
                    console.log("Marker or _lngLat is not defined.");
                }
                this.$store.commit('decrement');
                this.$root.$emit("bv::show::modal", "modal-addSpot");
                this.getSpotType();
                this.getLocations();
                this.getAddSpotCategoriesForSpotter();
            },
            getLatLong(e) {
                this.spotForm.longitude = e['lng'];
                this.spotForm.latitude = e['lat'];
            },
            compressImage(file) {
                const maxWidth = 1000;
                const maxHeight = 1000;
                const quality = 0.8;
                const mimeType = file.type;
                return new Promise((resolve, reject) => {
                new Compressor(file, {
                    maxWidth,
                    maxHeight,
                    quality,
                    mimeType,
                    success(result) {
                    resolve(result);
                    },
                    error(error) {
                    reject(error);
                    },
                });
                });
        },
            async onFileSelected(event, type) {
                this.version_id;
                this.imageType0 = type;
                this.spotForm.media_types[0] = type;
                console.log(this.spotForm.media_types[0]);
                if (this.spotForm.media_types[0] === 'audio') {
                    this.$refs.imageInput.reset();
                } else {
                    this.$refs.audioInput.reset();
                }
                if(this.spotForm.media_types[0] === 'image'){
                    if(this.version_id === 2 || this.version_id === 3){
                        const compressedImage = await this.compressImage(event.target.files[0]);
                        this.spotForm.image = compressedImage;
                        this.spotForm.url = URL.createObjectURL(compressedImage)
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        // this.form.image = event.target.files[0];
                        this.spotForm.image = event.target.files[0];
                        this.spotForm.url = URL.createObjectURL(this.spotForm.image)
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;
                        // this.form.url = URL.createObjectURL(this.form.image);
                        // this.imageSizeError = false;
                        // this.imageError = false;
                        }
                        else{
                            this.imageSizeError = true;
                        }
                    }
                   
                }
                else {
                    if (this.spotForm.media_types[0] === 'audio') {
                        if(this.version_id === 2 || this.version_id === 3){
                            if (event.target.files[0].size <= MAX_SIZE) {
                                this.spotForm.image = event.target.files[0];
                                this.spotForm.url = URL.createObjectURL(this.spotForm.image)
                                this.imageSizeError = false;
                                this.audioSizeError = false;
                                this.imageError = false;
                            }
                            else{
                                this.audioSizeError = true;
                            } 
                        }
                        else{
                            if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                                this.spotForm.image = event.target.files[0];
                                this.spotForm.url = URL.createObjectURL(this.spotForm.image)
                                this.imageSizeError = false;
                                this.audioSizeError = false; 
                                this.imageError = false;
                            }
                            else{
                                this.audioSizeError = true;
                            }
                        }
                    } 
                    else{
                        this.imageSizeError = true;
                    }
                    // else {
                    //     this.imageSizeError = true;
                    //     this.imageError = false;
                    // }
                    // this.imageSizeError = false;
                }
            },
            // onFileSelected1(event) {
            //     if (event.target.files[0].size <= 5000000) {
            //         this.selectedFile2 = event.target.files[0]
            //         this.url2 = URL.createObjectURL(this.selectedFile2)
            //         this.imageSizeError1 = false;
            //     } else {
            //         this.imageSizeError1 = true;
            //     }
            // },
            async onFileSelected1(event, type) {
                this.version_id;
                this.imageType1 = type;
                this.spotForm.media_types[1] = type;
                if (this.spotForm.media_types[1] === 'audio') {
                    this.$refs.image1Input.reset();
                } else {
                    this.$refs.audio1Input.reset();
                }
                if(this.spotForm.media_types[1] === 'image'){
                    if(this.version_id === 2 || this.version_id === 3){
                        const compressedImage = await this.compressImage(event.target.files[0]);
                        this.spotForm.image1 = compressedImage;
                        this.spotForm.url1 = URL.createObjectURL(compressedImage)
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.spotForm.image1 = event.target.files[0];
                        this.spotForm.url1 = URL.createObjectURL(this.spotForm.image1)
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                        }
                        else{
                            this.imageSizeError1 = true;
                        }
                    }
                } 
                else {
                    if (this.spotForm.media_types[1] === 'audio') {
                        if(this.version_id === 2 || this.version_id === 3){
                            if (event.target.files[0].size <= MAX_SIZE) {
                            this.spotForm.image1 = event.target.files[0];
                            this.spotForm.url1 = URL.createObjectURL(this.spotForm.image1)
                            this.imageSizeError1 = false;
                            this.audioSizeError1 = false; 
                            }
                            else{
                                this.audioSizeError1 = true;
                            }
                        }
                        else{
                            if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                                this.spotForm.image1 = event.target.files[0];
                                this.spotForm.url1 = URL.createObjectURL(this.spotForm.image1)
                                this.imageSizeError1 = false;
                                this.audioSizeError1 = false; 
                            }
                            else{
                                this.audioSizeError1 = true;
                            }
                        }
                        
                    } 
                    else{
                        this.imageSizeError1 = true;
                    }
                }
            },
            async onFileSelected2(event, type) {
                this.version_id;
                this.imageType2 = type;
                this.spotForm.media_types[2] = type;
                if (this.spotForm.media_types[2] === 'audio') {
                    this.$refs.image2Input.reset();
                } else {
                    this.$refs.audio2Input.reset();
                }
                if(this.spotForm.media_types[2] === 'image'){
                    if(this.version_id === 2 || this.version_id === 3){
                        const compressedImage = await this.compressImage(event.target.files[0]);
                        this.spotForm.image2 = compressedImage;
                        this.spotForm.url2 = URL.createObjectURL(compressedImage)
                        this.imageSizeError2 = false;
                        this.audioSizeError2 = false;
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                            this.spotForm.image2 = event.target.files[0];
                            this.spotForm.url2 = URL.createObjectURL(this.spotForm.image2)
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false;
                        }
                        else{
                            this.imageSizeError2 = true;
                        }
                    }    
                } 
                else {
                    if (this.spotForm.media_types[2] === 'audio') {
                        if(this.version_id === 2 || this.version_id === 3){
                            if (event.target.files[0].size <= MAX_SIZE) {
                            this.spotForm.image2 = event.target.files[0];
                            this.spotForm.url2 = URL.createObjectURL(this.spotForm.image2)
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false; 
                            }
                            else{
                                this.audioSizeError2 = true;
                            }
                        }
                        else{
                            if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                                this.spotForm.image2 = event.target.files[0];
                                this.spotForm.url2 = URL.createObjectURL(this.spotForm.image2)
                                this.imageSizeError2 = false;
                                this.audioSizeError2 = false; 
                            }
                            else{
                                this.audioSizeError2 = true;
                            }
                        }
                        
                    } 
                    else{
                        this.imageSizeError2 = true;
                    }
                }
            },
            getSpotType() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                API.getSpotType(
                    data,
                    data => {
                        this.spot_types = data.data;
                    },
                    err => {
                    }
                )

            },
            getAppSetting() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                API.getAppSetting(
                    data,
                    data => {
                        // console.log('test');
                        // console.log(data);
                        this.show_menu = data.data.show_menu;
                        this.show_category_count = data.data.show_category_count;
                        this.show_filter = data.data.show_filter;
                        this.version_id = data.version_id;
                        this.appImage = data.data.stock_image;
                        this.requirePhoto = data.data.require_photo;
                        // if(data.data.default_map == "mapbox://styles/mapbox/satellite-v13"){
                        //         this.adminMapStyle = "mapbox://styles/mapbox/satellite-v9";
                        //     }else{
                        //         this.adminMapStyle = data.data.default_map;
                        //     }
                        this.adminMapStyle = data.data.default_map;
                        if (localStorage.getItem('spotterLoggedIn') !== null) {
                            var spotter_settings = JSON.parse(localStorage.getItem('spotterClientSettings'));
                            this.spotterClient_id = spotter_settings.spotterClient_id;
                            this.spotterEmail = spotter_settings.email;
                            this.spotterAuth = localStorage.getItem('spotterLoggedIn');
                            if (spotter_settings.default_map) {
                                this.default_map = spotter_settings.default_map;
                            } else {
                                // if(data.data.default_map == "mapbox://styles/mapbox/satellite-v13"){
                                //     this.saveMapStylee('satellite-v9');
                                // }else{
                                //     this.default_map = data.data.default_map;
                                // }
                                this.default_map = data.data.default_map;
                            }
                        } else {
                            //    if(data.data.default_map == "mapbox://styles/mapbox/satellite-v13"){
                            //         this.saveMapStylee('satellite-v9');
                            //     }else{
                            //         this.default_map = data.data.default_map;
                            //     }
                            this.default_map = data.data.default_map;
                        }
                        this.default_map_zoom = data.data.default_map_zoom;
                        this.spotForm.requireLatLong = data.data.require_lat_lang;
                        this.defaultImage = data.data.stock_image;
                        this.default_latitude = (data.data.latitude) ? data.data.latitude : 39.452717;
                        this.default_longitude = (data.data.longitude) ? data.data.longitude : -123.813866;
                    },
                    err => {
                    })
            },
            getAddSpotCategoriesForSpotter() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                    users_setting_id:JSON.parse(localStorage.getItem('spotterClientSettings')).id
                }
                API.getAddSpotCategoriesForSpotter(
                    data,
                    data => {
                        this.categories = data.data;
                    },
                    err => {
                    }
                )

            },
            getLocations() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                API.getLocations(
                    data,
                    data => {
                        this.locations = data.data;
                    },
                    err => {
                    }
                )

            },
            dataCollectionQuestions() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                    category_id: this.spotForm.category_id,
                }
                API.dataCollectionQuestions(
                    data,
                    data => {
                        console.log(data);
                        this.questionArray = data.new_questions;
                        this.spotForm.questions = data.new_questions;
                    },
                    err => {
                    }
                )

            },
            addQuestions(event, question_id, category_id) {
                const data = {
                    category_id: category_id,
                    question_id: question_id,
                    answer: event.target.value,
                }
                this.spotForm.questions[question_id].answer = event.target.value.trim();
                // this.spotForm.questions.push(data)
            },
            registerSpot() {
                // working
                this.$v.$touch();
                this.$v.spotForm.$touch();
                if (this.requirePhoto === 1 && this.spotForm.image.length === 0) {
                    this.imageError = true;
                }


                if (this.$v.spotForm.$anyError || this.imageError || this.imageSizeError || this.imageSizeError1 || this.imageSizeError2 || this.audioSizeError || this.audioSizeError1 || this.audioSizeError2) {
                    return;
                }
                this.isDisabled = true;
                this.isLoading2 = true;
                const fd = new FormData();
                if (this.spotForm.image !== undefined && this.spotForm.image.length !== 0) {
                    fd.append('image', this.spotForm.image, this.spotForm.image.name)
                }


                if (this.spotForm.image1 !== undefined && this.spotForm.image1.length !== 0) {
                    fd.append('image1', this.spotForm.image1, this.spotForm.image1.name)
                }

                if (this.spotForm.image2 !== undefined && this.spotForm.image2.length !== 0) {
                    fd.append('image2', this.spotForm.image2, this.spotForm.image2.name)
                }

                var users_settings = JSON.parse(localStorage.getItem('spotterClientSettings'));


                fd.append('category_id', this.spotForm.category_id);
                fd.append('spot_type_id', this.spotForm.spot_type_id);
                fd.append('location_id', this.spotForm.location_id);
                fd.append('fix_location_id', this.spotForm.fix_location_id);
                fd.append('detail', this.spotForm.detail);
                fd.append('spot_date', this.spotForm.spot_date);
                fd.append('users_setting_id', users_settings.id);
                fd.append('media_types', JSON.stringify(this.spotForm.media_types));

                if (this.spotForm.latitude === '' || this.spotForm.latitude === null) {
                    fd.append('latitude', this.latitude);
                } else {
                    fd.append('latitude', this.spotForm.latitude);
                }

                if (this.spotForm.longitude === '' || this.spotForm.longitude === null) {
                    fd.append('longitude', this.longitude);
                } else {

                    fd.append('longitude', this.spotForm.longitude);
                }

                fd.append('questions', JSON.stringify(this.spotForm.questions));
                fd.append('client_url', window.location.href.split('#')[0]);
                API.addSpot(
                    fd,
                    data => {
                        this.isDisabled = false;
                        this.isLoading2 = false;
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            const markerColor = (data.data.single_question && data.data.single_question.category) ? data.data.single_question.category.hash_color : 'Red';
                            const d1 = {
                                type: "Feature",
                                properties: {
                                    id: data.data.id,
                                    title: data.data.spot_type.name,
                                    spot_id: data.data.spot_type_id,
                                    description: data.data.location.name,
                                    spotted_by: (data.data.spotter_user && data.data.spotter_user.name) ? data.data.spotter_user.name : '',
                                    spotted_username: (data.data.spotter_user && data.data.spotter_user.user_name) ? data.data.spotter_user.user_name : '',
                                    iconUrl: this.$server_url + '/api' + data.data.image,
                                    markerColor: markerColor,
                                },
                                geometry: {
                                    type: "Point",
                                    coordinates: [data.data.longitude, data.data.latitude]
                                }
                            }
                            this.data2.features.push(d1);
                            // this.map.getSource("earthquakes").setData(this.data2);
                            // Update marker colors for existing features
                            let categoryColors = this.data2.features.map(feature => feature.properties.markerColor || '');
                            
                            // Set the GeoJSON source data with individual colors for each feature
                            const featuresWithColors = this.data2.features.map((feature, index) => {
                                return {
                                    ...feature,
                                    properties: {
                                        ...feature.properties,
                                        'icon-color': categoryColors[index] || '',
                                        'icon-halo-color': categoryColors[index] || ''
                                    }
                                };
                            });

                            // Update the GeoJSON source data on the map
                            this.map.getSource('earthquakes').setData({
                                type: 'FeatureCollection',
                                features: featuresWithColors
                            });
                        }
                        else if (data.status === 410) {
                            // console.log(data.data.image1);
                            if (data.data.image) {
                                this.imageSizeError = true;
                            }
                            if (data.data.image1) {
                                this.imageSizeError1 = true;
                            }
                            if (data.data.image2) {
                                this.imageSizeError2 = true;
                            }
                        }
                        else if(data.status === 500) {
                            this.$snotify.error(data.message)
                            this.spotForm.fix_location_id = ''
                            this.nextSteps(1)
                            return;
                        }
                        this.spotForm.category_id = '';
                        this.spotForm.spot_type_id = '';
                        this.spotForm.location_id = '';
                        this.spotForm.fix_location_id = '';
                        this.spotForm.detail = '';
                        this.spotForm.longitude = this.marker._lngLat.lng;
                        this.spotForm.latitude = this.marker._lngLat.lat;
                        // this.spotForm.spot_date = '';
                        this.spotForm.questions = [];
                        this.spotForm.image = [];
                        this.spotForm.image1 = [];
                        this.spotForm.image2 = [];
                        this.spotForm.url = '';
                        this.spotForm.url1 = '';
                        this.spotForm.url2 = '';
                        this.imageType0 = '';
                        this.imageType1 = '';
                        this.imageType2 = '';
                        this.spotForm.media_types = [];
                        this.$refs.imageInput.reset();
                        this.$refs.audioInput.reset();
                        this.$v.spotForm.$reset();
                        // this.dataCollectionQuestions();
                        this.$root.$emit("bv::hide::modal", "modal-addSpot");
                    },
                    err => {
                        this.isLoading2 = false;
                        // console.log('arrrrr', err);
                        // if(err.request.status === 401){
                        //     this.isDisabled = false;
                        //     this.$root.$emit("bv::hide::modal", "modal-addSpot");
                        //     if(!err.status) {
                        //         alert('Unathenticated User. You will be logged out.');
                        //         this.$root.$emit('logout-event');
                        //     }
                        // }
                    }
                )
            },
            changePinLong(event) {
                this.spotForm.longitude = event;
                this.marker.setLngLat([this.spotForm.longitude, this.spotForm.latitude]).addTo(this.map);
            },
            changePinLat(event) {
                this.spotForm.latitude = event;
                this.marker.setLngLat([this.spotForm.longitude, this.spotForm.latitude]).addTo(this.map);
            },
            openFullScreenMap() {
                let route = this.$router.resolve({
                    name: 'full_screen_map',
                    params: {
                        id: 'Cl-864196'
                    }
                });
                // let route = this.$router.resolve('/link/to/page'); // This also works.
                window.open(route.href, '_blank');
            },
            formatDate(date) {
                return moment(String(date)).format('MM-DD-YYYY HH:mm')
            },
            saveMapStyle(value) {

                this.map.remove();
                // document.getElementById('light-v100').checked = false;
                this.is_heatMap = false;
                this.default_map = 'mapbox://styles/mapbox/' + value;
                this.saveDefaultMap();
                this.getClientSpots();
            },
            saveMapStylee(value) {
                this.map.remove();
                this.is_heatMap = true;
                this.default_map = 'mapbox://styles/mapbox/' + value;
                // document.getElementById('satellite-v9').checked = false;
                // this.saveDefaultMap();
                // uncheck
                // this.heatMAp = 2;
                // this.initHeatMap();
                // heatMap.getAppSettings();
                this.getClientAdminSpots();

            },
            saveDefaultMap() {
                if (localStorage.getItem('spotterLoggedIn') !== null) {
                    let data = {
                        default_map: this.default_map,
                        client_url: window.location.href.split('#')[0],
                    }
                    API.saveDefaultMap(
                        data,
                        data => {
                            localStorage.setItem('spotterClientSettings', JSON.stringify(data.data));
                        },
                        err => {

                        }
                    )
                }
            },
            getUserSettings() {
                var spotter_settings = JSON.parse(localStorage.getItem('spotterClientSettings'));
                this.spotterEmail = spotter_settings.email;
                this.spotterAuth = localStorage.getItem('spotterLoggedIn');
                var d_map = true;
                if (this.adminMapStyle === this.default_map) {
                    d_map = true;
                }
                if (this.adminMapStyle !== this.default_map) {
                    d_map = false;
                }
                if (d_map === false && (this.default_map !== spotter_settings.default_map)) {
                    this.saveDefaultMap();
                } else {
                    this.default_map = spotter_settings.default_map;
                    this.map.remove();
                    this.getClientSpots();
                }
            },
            popDetail(id) {
                alert(id)
            },
            goNext() {
                //alert(this.$refs.wizard.activeTabIndex);
                this.$refs.wizard.nextTab();
            },
            beforeTabSwitch() {
                alert("This is called before switchind tabs")
                // return true;
            },
            getError(error) {
            },
            callFunction(value) {
                return value;
            },
            nextStep() {
            },
            nextSteps(id) {
                if (id === 1) {
                    this.current_step = id;
                    this.width = "33.3333%"
                    this.active2 = false
                    this.active3 = false
                }

                if (id === 2) {
                    this.$v.spotForm.category_id.$touch();
                    this.$v.spotForm.spot_type_id.$touch();
                    this.$v.spotForm.location_id.$touch();
                    this.$v.spotForm.latitude.$touch();
                    this.$v.spotForm.longitude.$touch();
                    if (this.$v.spotForm.longitude.$error || this.$v.spotForm.latitude.$error || this.$v.spotForm.spot_type_id.$invalid || this.$v.spotForm.location_id.$invalid) {
                        return false;
                    } else {
                        this.current_step = id;
                        this.width = "66.6666%"
                        this.active2 = true
                        this.active3 = false
                    }
                }

                if (id === 3) {
                    this.isSubmittedStep2 = true;
                    var error = false;
                    this.spotForm.questions.forEach((values, index) => {
                        values.questions.forEach((values, index) => {
                            if (values.answer === '' && values.required === 1) {
                                if (error === true) {
                                    error = true;
                                } else {
                                    error = true;
                                }
                            }
                        });
                    });
                    if (!error) {
                        this.current_step = id;
                        this.width = "100%";
                        this.active2 = true
                        this.active3 = true
                    }
                }
                if (id === 'submit') {
                    console.log('submitted');
                    this.registerSpot();
                }
            },
            openFilters() {
                this.$root.$emit("bv::show::modal", "modal-map-filters");
                // this.$refs.filterComponent.getFiltersData();
            },
            openSiderBar() {
                this.$root.$emit("bv::toggle::collapse", "sidebar-no-header");
            },
            applyFilter(params) {
                // this.$refs.sidebar.getQuestionAnswerCounts(params);
                console.log('params :', params);
                console.log('filters popup');
                let data = {
                    spot_id: params.spotId,
                    date_from: params.dateFrom,
                    date_to: params.dateTo,
                    questions: params.questions,
                    categories: params.categories,
                    locations: params.locations,
                    spotTypes: params.spotTypes,
                    surveys: params.surveys,
                    selectedQuestions: params.selectedQuestions,
                    selected_questions: params.selected_questions,
                    selected_questions_answer: params.selected_questions_answer,
                    client_url: window.location.href.split('#')[0],
                    countRangeFilter: params.countRangeFilter,
                    countRangeValues: params.countRangeValues,
                }
                console.log('filter data', data);
                API.getSpotsByMapFilters(data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            // this.data2.features = [];
                            this.mapFeatures = [];
                            this.totalSpots = data.total_spots;
                            console.log('data after filter:', data);
                            this.$refs.sidebar.assignQuestions(data.countableQuestions);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                            this.$refs.filterComponent.changeSliderValue(data.range);
                            const spot_dImage = data.default_image;
                            data.data.forEach(data => {
                                let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                                if (!exists) {
                                    const d1 = {
                                        type: "Feature",
                                        properties: {
                                            id: data.id,
                                            title: (data.spot_type) ? data.spot_type.name : '',
                                            // spot_id: data.spot_type_id,
                                            spot_id: data.spot_id,
                                            description: (data.location) ? data.location.name : '',
                                            spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                                            spotted_username: (data.spotter_user && data.spotter_user.user_name) ? data.spotter_user.user_name : '',
                                            spot_date: this.formatDate(data.spot_date),
                                            iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                            markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'

                                        },
                                        geometry: {
                                            type: "Point",
                                            coordinates: [data.longitude, data.latitude]
                                        }
                                    }
                                    this.mapFeatures.push(d1);
                                }
                            })
                            let categoryColors = [];

                            this.mapFeatures.forEach((feature) => {
                                // Collect all category colors
                                categoryColors.push(feature.properties.markerColor || '');
                            });
                            // Set the GeoJSON source data with individual colors for each feature
                            const featuresWithColors = this.mapFeatures.map((feature, index) => {
                                return {
                                    ...feature,
                                    properties: {
                                        ...feature.properties,
                                        'icon-color': categoryColors[index] || '',
                                        'icon-halo-color': categoryColors[index] || ''
                                    }
                                };
                            });

                            this.map.getSource('earthquakes').setData({
                                type: 'FeatureCollection',
                                features: featuresWithColors
                            });
                            // this.data2 = {
                            //     "type": "FeatureCollection",
                            //     "crs": {
                            //         "type": "name",
                            //         "properties": {
                            //             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            //         }
                            //     },
                            //     "features": this.mapFeatures
                            // }
                            // this.map.getSource("earthquakes").setData(this.data2);
                            console.log('applyFilterresponse', data)
                        } else if (data.status === 500) {
                            this.$snotify.error(data.message);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                        }
                    },
                    err => {
                        console.log(err);
                        this.$refs.filterComponent.StopFilterLoading();
                        this.$refs.filterComponent.StopResetLoading();
                    }
                );
            }
        }
    };
</script>

<style lang="scss" scoped>
    #coordinates {
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        position: fixed;
        left: 14px;
        padding: 5px 10px;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        border-radius: 3px;
        z-index: 99999999999;
        left: unset;
        bottom:10px;
        margin-left: 10px;
    }
    .basemap {
        width: 100%;
        height: 100%;
    }

    #menu {
        position: fixed;
        background: #efefef;
        padding: 10px;
         padding-top: 22px;
        padding-top: 8px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
        left: 70px;
        height: 58px;
    }
    #question-counts {
        position: fixed;
        background: #efefef;
        padding: 10px;
         padding-top: 22px;
        padding-top: 8px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
        left: 580px;
        height: 58px;
    }
    .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }

    .variant-orange {
        color: #fff !important;
        background-color: #fa6400;
        border-color: #fa6400;
    }
    .new-spotter-lang-select {
        display: inline-block;
    }

    .menu-item {
        display: inline-block;
    }
    .mobile-filter-group {
        display: none !important;
    }
    /* .horizontal-menu .bottom-navbar .page-navigation {
        min-height: calc(100vh - 320px);
        height: 100%;
        overflow-y: auto;
    } */

    /* @media (max-width: 850px) {
        .menu-items {
            width: 75%;
            height: auto !important;
        }
    } */

    @media (max-width: 860px) {
        .mobile-filter-group {
            position: absolute;
            bottom: 15px;
            right: 6px;
            display: flex !important;
            flex-direction: column;
        }
        .mobile-filter-group button:nth-child(2) {
            margin-top: 10px;
            margin-left: 0px !important;
        }
        .w-btn {
            display: none;
        }
        .map-container {
            top: 58px;
        }
        #menu {
            width: 89%;
        }
    }

    @media (max-width: 767px) {
        #menu {
            left: 58px;
        }
    }
      .lang-e4 {
        display: inline-flex;
        align-items: center;
    }

    /* @media (max-width: 400px) {
        .menu-items {
            width: 68%;
        }
        .f-btn {
            margin-left: 0px !important;
        }
    } */

    /* @media (max-width: 390px) {
        .m-input {
            margin-left: 0px !important;
        }
        .new-spotter-lang-select {
            margin-left: 0px !important;
        }
    } */

    @media (max-width: 375px) {
        .map-label {
            font-size: 13px;
        }
        .new-spotter-lang-select {
            margin-left: 1px !important;
        }
    }
    .text-right {
        text-align: right;
        margin-bottom: 20px;
    }
</style>